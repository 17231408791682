﻿.video-container {
  position: relative;

  &:hover {
    cursor: pointer;

    .videobutton svg circle {
      fill: $ally-magenta;
    }
  }

  video {
    max-width: 100%;
    max-height: 100vh;
  }

  .videobutton {
    border: 0;
    text-align: center;
    background: none;
    box-sizing: border-box;
    margin-top: 23%;
    cursor: pointer;
    transition: all 0.3s;

    &.imagetrailer {
      @include media-breakpoint-down(md) {
        margin-top: 75%;
      }
    }
    &.showreel {
      @include media-breakpoint-down(md) {
        margin-top: 50%;
      }
    }

    &:hover svg circle {
      fill: $ally-magenta;
    }

    &.playing {
      display: none;
    }
  }
}
