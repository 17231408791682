.card {
  padding: 1rem;
  color: $ally-white;
  border-radius: 0;
  height: 100%;

  ul {
    padding-left: 1.2rem;

    li {
      color: $ally-white;
      line-height: 1.7rem;
    }
  }
}
