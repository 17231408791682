﻿footer {
  .footer_holder {
    z-index: 10;
    top: 0;
    -webkit-transition: 1s;
    transition: 1s;
    font-size: 16px;

    a.link {
      .arrow-icon {
        @media screen and (max-width: 767px) {
          top: 8px;
          margin-left: 2px;
        }
      }
    }

    &.left {
      left: 2%;
      text-align: center;
      @media screen and (min-width: 767px) {
        text-align: left;
      }
    }
    &.right {
      right: 2%;
      text-align: center;
      clear: both;
      @media screen and (min-width: 767px) {
        text-align: right;
      }
    }
  }
}
