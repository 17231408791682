﻿a {
  text-decoration: none;
  &.link {
    cursor: pointer;
    position: relative;
    display: inline-block;

    &:hover {
      text-decoration: none;
      color: $ally-magenta !important;
      .arrow-icon {
        margin-left: 12px;
      }

      .arrow-icon--circle {
        stroke-dashoffset: 0;
      }
    }

    .arrow-icon {
      position: absolute;
      top: 7px;
      width: 8px;
      height: 8px;
      margin-left: 7px;
      border-left: 1px solid #f05ab9;
      border-bottom: 1px solid #f05ab9;
      transform: rotate(-135deg);
      box-sizing: border-box;
      transition: margin 0.3s ease;

      @media screen and (max-width: 767px) {
        top: 20px;
      }

      &.big {
        top: 13px;
        width: 12px;
        height: 12px;
        border-width: 2px;

        @media screen and (max-width: 479px) {
          top: 8px;
        }
      }

      &.bigg {
        top: auto;
        bottom: 10px;
        width: 12px;
        height: 12px;
        border-width: 2px;
      }
    }
  }
}

.btn {
  .arrow-icon {
    position: absolute !important;
    top: 20px;
    width: 16px;
    height: 16px;
    margin-left: 42px;
    border-left: 3px solid #f6f602;
    border-bottom: 3px solid #f6f602;
    transform: rotate(-135deg);
    box-sizing: border-box;
    opacity: 0;
  }

  &:hover {
    .arrow-icon {
      opacity: 100%;
      transition: opacity 0.3s;
    }
  }
}
