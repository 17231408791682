﻿.modal {
  padding-right: 0 !important;
  font-size: 16px;

  .modal-fullscreen {
    min-width: 100%;
    margin: 0;

    .logo {
      width: 80px;
    }

    .modal-content {
      min-height: 100vh;

      .modal-header {
        border: 0;
      }

      .close {
        position: absolute;
        top: 30px;
        right: 39px;
        font-size: 16px;
        color: black;

        small {
          margin-top: 0.2rem;
          margin-right: 0.5rem;

          @media screen and (max-width: 480px) {
            display: none;
          }
        }

        .close-toggle {
          position: relative;
          display: inline-block;
          height: 27px;
          width: 38px;
          cursor: pointer;

          .top {
            transform: translateY(11px) translateX(0) rotate(45deg);
            background: $ally-black;
          }
          .middle {
            opacity: 0;
            background: $ally-black;
          }

          .bottom {
            transform: translateY(-11px) translateX(0) rotate(-45deg);
            background: $ally-black;
          }

          span {
            background: $ally-black;
            border: none;
            height: 5px;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;

            &:nth-of-type(2) {
              top: 11px;
            }

            &:nth-of-type(3) {
              top: 22px;
            }
          }
        }

        &:hover {
          color: $ally-magenta;
          span {
            background-color: $ally-magenta !important;
          }
        }
      }
    }
  }
}

[data-serialtip-target] {
  display: none;
  position: absolute;
  z-index: 1001;
  max-width: 500px;
  margin: 15px;
  margin-top: -8rem;
}
[data-serialtip-target].is-alignment-top {
  margin-top: 0;
}
[data-serialtip-target].is-alignment-right {
  margin-right: 0;
}
[data-serialtip-target].is-alignment-bottom {
  margin-bottom: 0;
}
[data-serialtip-target].is-alignment-left {
  margin-left: 0;
}
[data-serialtip-target] .serialtip-title {
  margin-bottom: 15px;
  font-size: 18px;
  color: #000;
  font-weight: 400;
}
[data-serialtip-target] .serialtip-close {
  position: absolute;
  top: 0;
  right: 0;
  font-family: sans-serif;
  font-weight: lighter;
  font-size: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-top: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  transform: rotate(45deg) scale(1);
  z-index: 10;
}
[data-serialtip-target] .serialtip-close:hover {
  color: $ally-magenta;
}

.serialtip-default {
  padding: 2.5rem 1.5rem 1.5rem 1.5rem;
  background-color: $ally-white;
  box-shadow: 5px 5px 35px 5px rgba(0, 0, 0, 0.1);
  text-align: center;

  p {
    padding: 0.5rem;
  }
}
.serialtip-default:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: solid transparent;
}
