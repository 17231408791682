﻿/*@font-face {
  font-family: "AriBlack";
  font-style: normal;
  font-weight: normal;
  src: local("AriBlack"), url("/fonts/ARIBLK.woff") format("woff");
}*/
@font-face {
  font-family: "AriBlack";
  src: url("/fonts/Arial-Black.woff2") format("woff2"),
    url("/fonts/Arial-Black.woff") format("woff"),
    url("/fonts/Arial-Black.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: Arial, sans-serif;
  font-size: 25px;
  font-weight: 400;
}

h1 {
  font-size: 40px;
  line-height: 56px;
  z-index: 1;

  strong {
    font-family: "AriBlack", sans-serif;
    font-size: 40px;
  }
}
h2 {
  font-family: "AriBlack", sans-serif;
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 0;
}
h3 {
  font-size: 25px;
  font-family: "AriBlack", sans-serif;
  line-height: 35px;
  margin-bottom: 0;
}

.btn {
  font-size: 22px;
  font-family: "AriBlack", sans-serif;
  line-height: 30px;
}

.fw-bold {
  font-family: "AriBlack", sans-serif;
  font-weight: normal;
}

@media screen and (max-width: 480px) {
  body {
    font-size: 19px;
  }
  h1,
  h1 strong {
    font-size: 26px;
    line-height: 36px;
  }
  h2 {
    font-size: 19px;
    line-height: 26px;
  }
}
