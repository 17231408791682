﻿.btn {
  border-radius: 0;
  padding: 0.8rem 5rem;
  cursor: pointer;

  &.btn-primary {
    color: $ally-yellow;
    border: 1px solid transparent;
    border-radius: 40px;
    position: relative;

    span {
      position: relative;
      color: $ally-yellow;
    }

    &::before {
      transition: all 0.25s;
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      right: -1px;
      border-radius: 40px;
      background: $ally-magenta;
      width: 100%;
    }

    &:hover {
      background: $ally-yellow;
      span {
        color: $ally-magenta;
      }

      &::before {
        width: 55px;
        background: $ally-magenta;
      }
    }

    &:hover {
      background-position: -50px bottom;
      color: $ally-yellow !important;
    }
    &.active {
      border: 2px solid $ally-yellow !important;
      background: $ally-yellow !important;
      color: $ally-magenta !important;
    }
  }
}

.btn_modal_close {
  z-index: 1000;
  position: fixed;
  top: 30px;
  right: 39px;
  background-color: transparent;
  color: $ally-black !important;
  border: 0;
  padding: 0;
  font-size: 26px;
  width: 26px;
  opacity: 1;

  &:hover {
    svg {
      transform: rotate(90deg) scale(1);
    }
    svg .cls-1 {
      stroke: $ally-magenta !important;
    }
  }

  svg {
    width: 100%;
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  div {
    font-size: 13px;
    line-height: normal;
    position: absolute;
    top: 30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: auto;
    text-align: center;
    text-shadow: none;

    @include media-breakpoint-up(md) {
      display: block;
      top: 7px;
      left: -150px;
      width: 150px;
      text-align: right;
      padding-right: 10px;
      transform: translateX(0);
    }
  }
}
