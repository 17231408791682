﻿.cookie_holder {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 100;
  bottom: -300px;
  color: $ally-white;
  background-color: $ally-black;
  -webkit-transition: 1s;
  transition: 1s;

  &.show {
    bottom: 0;
  }

  * {
    font-size: 13px;
  }

  a {
    cursor: pointer;
  }

  .btn {
    font-family: Arial, sans-serif;
    font-weight: normal;
    padding: 0.8rem;
    line-height: normal;
  }
}
